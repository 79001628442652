import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import ContactShopContainer from '@/containers/ContactShop/ContactShopContainer';

import { ShopContactPageQuery } from '../../../graphql-types';

interface Props {
  data: ShopContactPageQuery;
}

export const pageQuery = graphql`
  query ShopContactPage {
    prismicContactPage {
      data {
        meta_title
        meta_description
        ...ContactMain
      }
    }
    allPrismicContactPageBodyCompanystats {
      edges {
        node {
          primary {
            type
          }
          items {
            number {
              raw
            }
            label {
              text
            }
          }
        }
      }
    }
    prismicCompanyInfos {
      ...CompanyInfos
    }
    prismicCompanyInfosShop {
      ...CompanyInfosShop
    }
  }
`;

const Contact = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } = data?.prismicContactPage?.data || {};

  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title || 'title'}
        description={meta_description || 'description'}
      />
      <ContactShopContainer data={data} type="magasin" />
    </Layout>
  );
};

export default Contact;
