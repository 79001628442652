import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import ContactForm from '@/components/molecules/ContactForm';

import mq from '@/styles/mq';

import Company from '@/domain/entities/Company';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  companyInfos?: Company;
}

const ContactShopMainRoot = styled(Grid)`
  align-items: stretch;
  margin-bottom: ${({ theme }) => theme.spacing(10)};

  ${mq('lg')} {
    margin-bottom: 0;
  }
`;
const Title = styled(Typography)`
  text-align: center;

  ${mq('lg')} {
    text-align: left;
  }
`;
const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;

  ${mq('lg')} {
    text-align: left;
  }
`;
const LeftSideGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(10)};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: 0;
    padding-right: ${({ theme }) => theme.spacing(8)};
    max-width: calc(${({ theme }) => theme.breakpoints.lg}px * 7 / 12);
    margin-left: auto;
    margin-right: 0;
  }
`;
const RightSideGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(6)};
  background-color: ${({ theme }) => theme.color.secondary.main};

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
  }
`;
const RightContent = styled('div')`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq(600)} {
    text-align: center;
  }

  ${mq('lg')} {
    text-align: left;
    max-width: calc(${({ theme }) => theme.breakpoints.lg}px * 5 / 12);
    margin-right: auto;
    margin-left: 0;
    padding-bottom: 0;
  }
`;

const ShopTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
const InfoLabel = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ContactShopMain = ({
  title,
  description,
  companyInfos,
}: Props): JSX.Element => {
  const {
    street,
    address_additional,
    postal_code,
    city,
    phone_number,
    email,
    schedule,
  } = companyInfos || {};

  return (
    <ContactShopMainRoot container>
      <LeftSideGrid xxs={12} lg={7}>
        <Title variant="h1">{title}</Title>
        <Description variant="h4">{description}</Description>
        <ContactForm form_name="magasin" />
      </LeftSideGrid>
      <RightSideGrid xxs={12} lg={5}>
        <RightContent>
          <ShopTitle variant="h2">Le magasin</ShopTitle>
          <InfoLabel color="textSecondary">Numéro de téléphone</InfoLabel>
          <Typography>{phone_number}</Typography>
          <InfoLabel color="textSecondary">Adresse e-mail</InfoLabel>
          <Typography>{email}</Typography>
          <InfoLabel color="textSecondary">Adresse</InfoLabel>
          <Typography>{street}</Typography>
          <Typography>{address_additional}</Typography>
          <Typography>
            {postal_code} {city}
          </Typography>
          <InfoLabel color="textSecondary">Horaire</InfoLabel>
          <Typography
            css={css`
              li {
                list-style: inside;
              }
            `}
          >
            {schedule}
          </Typography>
        </RightContent>
      </RightSideGrid>
    </ContactShopMainRoot>
  );
};

export const query = graphql`
  fragment ContactMain on PrismicContactPageDataType {
    page_title {
      raw
    }
    page_description {
      raw
    }
  }
`;

export default ContactShopMain;
